import { Theme, Typography, styled } from '@mui/material';
import Chip from '@mui/material/Chip';
import React from 'react';

const selectChipTextColor = (theme: Theme, active: boolean, disabled: boolean) => {
  if (disabled) return `${theme.palette.surface.text}${theme.palette.other.opacityDisabled}`;
  if (active) return `${theme.palette.secondary.main}${theme.palette.other.opacityHigh}`;
  return undefined;
};

const baseChipStyle: React.CSSProperties = {
  marginRight: '8px',
  marginBottom: '8px',
};

const StyledChip = styled(Chip, {
  shouldForwardProp: (prop) => prop !== 'active' && prop !== 'disabled',
})<{ active: boolean; disabled: boolean }>(({ theme, active, disabled }) => ({
  ...baseChipStyle,
  color: selectChipTextColor(theme, active, disabled),
  backgroundColor: active ? `${theme.palette.secondary.main}${theme.palette.overlay.opacity.selected}` : undefined,
}));

type EventCategoryProps = {
  title?: string;
  isMobile?: boolean;
  categories?: React.ReactNode[];
  disabled?: boolean;
  active?: boolean;
};

const EventCategory = ({
  title = 'Category',
  isMobile = false,
  categories = [],
  disabled = false,
  active = false,
}: EventCategoryProps) => {
  return (
    <>
      <Typography variant="subtitle2" component="p" style={{ marginTop: isMobile ? '8px' : '16px' }}>
        {title}
      </Typography>
      <div
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          marginTop: isMobile ? '8px' : '16px',
        }}
      >
        {categories.map((cat) => (
          <StyledChip key={`category-${cat}`} active={active} disabled={disabled} label={cat} />
        ))}
        {categories.length === 0 ? (
          <Typography variant="body2" component="p">
            No Category applied
          </Typography>
        ) : null}
      </div>
    </>
  );
};

export default EventCategory;

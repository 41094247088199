import React from 'react';

import { Divider, Typography, styled } from '@mui/material';
import { format } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';

const DividerText = styled(Typography)(({ theme }) => ({
  color: `${theme.palette.surface.text}${theme.palette.other.opacityMedium}`,
  textAlign: 'center',
}));

const DividerContainer = styled('div')(({ theme }) => ({
  paddingBottom: '8px',
  [theme.breakpoints.up('md')]: {
    paddingBottom: '16px',
  },
}));

interface Props {
  date: Date | string;
}

const StartEventDivider: React.FC<Props> = ({ date }) => {
  const formattedDate = format(utcToZonedTime(date, 'Europe/Stockholm'), 'iiii, MMM do');

  return (
    <DividerContainer>
      <DividerText>{formattedDate}</DividerText>
      <Divider />
    </DividerContainer>
  );
};

export default StartEventDivider;

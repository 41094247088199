import * as Backend from 'queries/BackendRequest';
import { UserTicket } from 'types/types';

const fetchUserTickets = async (pid: string): Promise<UserTicket[]> => {
  const result = await Backend.request<UserTicket[]>('get', `/user/${pid}/tickets`);
  return result.data.data;
};

export const getUserTicketsQuery = (pid: number | string) => ({
  queryKey: ['getUserTicketsQuery', pid],
  queryFn: () => fetchUserTickets(pid as string),
  enabled: !!pid,
});

export default getUserTicketsQuery;

import * as Backend from 'queries/BackendRequest';

export interface EventGroup {
  id: number;
  name: string;
  isBroadcasted: boolean;
  eventIDs: number[];
  ticketLimit: number;
}

const fetchEventGroups = async (pid: number | string): Promise<EventGroup[]> => {
  const result = await Backend.request<{ [x: number]: EventGroup }>('GET', `user/${pid}/event-group`);

  const eventGroups: EventGroup[] = [];
  Object.entries(result.data.data).forEach(([, value]) => {
    eventGroups.push(value);
  });

  return eventGroups;
};

export const getEventGroupsQuery = (pid: number | string) => ({
  queryKey: ['getEventGroupsQuery', pid],
  queryFn: () => fetchEventGroups(pid),
  enabled: !!pid,
});

export default fetchEventGroups;

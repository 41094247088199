import { styled } from '@mui/material';
import Typography from '@mui/material/Typography';
import React from 'react';
import ReactMarkdown from 'react-markdown';
import 'property-information';
import Emojify from '/components/emojify/Emojify';

const emojiOpactiy = (active: boolean, disabled: boolean) => {
  if (active) return 1.0;
  if (disabled) return 0.38;
  return 0.6;
};

const StyledEmojify = styled(Emojify)<{ active: boolean; disabled: boolean }>(({ active, disabled }) => ({
  '& .emoji': {
    opacity: emojiOpactiy(active, disabled),
    height: '1.25em',
    width: '1.25em',
    margin: '0 .05em 0 .1em',
    verticalAlign: '-0.1em',
  },
}));

type EventDescriptionProps = { disabled?: boolean; active?: boolean; description?: string; [key: string]: any };

const EventDescription = ({ disabled = false, active = false, description = '', ...props }: EventDescriptionProps) => {
  return (
    <Typography variant="body2" component="span" {...props}>
      <StyledEmojify active={active} disabled={disabled}>
        <div className="& p, ul" style={{ marginTop: '16px', marginBottom: '0px' }}>
          <ReactMarkdown>{description}</ReactMarkdown>
        </div>
      </StyledEmojify>
    </Typography>
  );
};

export default EventDescription;

import { Card, CardContent, Collapse, Divider, Theme, styled } from '@mui/material';
import React, { useState } from 'react';
import EventHeader from './EventHeader';

const getCardColor = (theme: Theme, active: boolean, disabled: boolean) => {
  if (disabled) return `${theme.palette.surface.text}${theme.palette.other.opacityDisabled}`;
  if (active) return `${theme.palette.secondary.main}${theme.palette.other.opacityHigh}`;
  return undefined;
};

const RootCard = styled(Card, {
  shouldForwardProp: (prop) => prop !== 'isMobile' && prop !== 'active' && prop !== 'disabled',
})<{ isMobile: boolean; active: boolean; disabled: boolean }>(({ theme, isMobile, active, disabled }) => ({
  padding: isMobile ? '8px' : '8px 16px',
  marginBottom: isMobile ? '8px' : '16px',
  color: getCardColor(theme, active, disabled),
  backgroundColor: active ? `${theme.palette.secondary.main}${theme.palette.overlay.opacity.selected}` : undefined,
}));

const StyledCardContent = styled(CardContent)(() => ({
  padding: 0,
  '&:last-child': {
    paddingBottom: 0,
  },
}));

interface Props {
  title?: string;
  subtitle?: string;
  isMobile?: boolean;
  disabled?: boolean;
  active?: boolean;
  statistics?: React.ReactNode;
  children?: React.ReactNode;
  button?: React.ReactNode;
  elevation?: number;
  disableCollapse?: boolean;
  // eslint-disable-next-line react/no-unused-prop-types
  noPadding?: boolean;
}

/**
 * Displays an event card with customizable content
 */
const EventCard: React.FC<Props> = ({
  title,
  subtitle,
  isMobile = false,
  disabled = false,
  active = false,
  statistics,
  children,
  button,
  elevation,
  disableCollapse,
}) => {
  const [expanded, setExpanded] = useState(false);
  const handleExpanded = () => setExpanded(!expanded);

  return (
    <RootCard
      elevation={elevation === undefined ? 1 : elevation}
      isMobile={isMobile}
      active={active}
      disabled={disabled}
    >
      <StyledCardContent>
        <EventHeader
          title={title}
          subtitle={subtitle}
          expanded={expanded}
          onClick={handleExpanded}
          hidden={disableCollapse}
        />
        {statistics}
        {!disableCollapse ? (
          <>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
              <Divider style={{ marginTop: isMobile ? '8px' : '16px' }} />
              {children}
            </Collapse>
            {button}
          </>
        ) : (
          <>{children}</>
        )}
      </StyledCardContent>
    </RootCard>
  );
};

export default EventCard;

import * as Backend from '/queries/BackendRequest';

interface ProjectTexts {
  [key: string]: string;
}

const fetchPrivateText = async (pid: string): Promise<ProjectTexts> => {
  const result = await Backend.request('GET', `/content/${pid}/strings`);
  return result.data?.data;
};

const getPrivateText = (pid: string) => ({
  queryKey: ['getPrivateText', pid],
  queryFn: () => fetchPrivateText(pid),
  enabled: !!pid,
  gcTime: 1000 * 60 * 60 * 24, // 1 day
  retry: 3,
  retryDelay: 500,
});

export default getPrivateText;

import { EventFilter } from 'components/eventFilter/useEventFilter';
import { format } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { UserTicket } from 'queries/admin-users/admin-user-tickets';
import { EventGroup } from 'queries/event/user-event-groups';
import { EventStats } from 'queries/event/user-event-stats';
import { UserEvent } from 'types/types';
import { BookingSearchFilter } from '../pages/admin/users/hooks/useSearchFilter';

const checkDate = (entry: UserTicket | UserEvent, filter: BookingSearchFilter | EventFilter) => {
  const startDatetime = new Date(Date.parse(entry.startDatetime));
  startDatetime.setHours(0, 0, 0, 0);

  const endDatetime = new Date(Date.parse(entry.endDatetime));
  endDatetime.setHours(0, 0, 0, 0);

  const isBeforeStart = filter.startDate ? startDatetime >= new Date(filter.startDate as string) : true;
  const isAfterEnd = filter.endDate ? endDatetime <= new Date(filter.endDate as string) : true;

  return isBeforeStart && isAfterEnd;
};

const checkPrice = (entry: UserTicket | UserEvent, filters: BookingSearchFilter | EventFilter) => {
  const startPrice = filters.startPrice !== undefined && filters.startPrice !== null ? filters.startPrice : 0;
  const endPrice = filters.endPrice !== undefined && filters.endPrice !== null ? filters.endPrice : 9999;
  const result = entry.basePriceSek >= startPrice && entry.basePriceSek <= endPrice;
  return result;
};

export const filterEventList = (
  data: UserEvent[] | UserTicket[],
  filters: EventFilter,
  eventIdList: number[],
  statsList: EventStats[],
  eventGroups: EventGroup[],
  search: string
) => {
  // check data is existing or filters are
  if (!data || data.length === 0) {
    return [];
  }

  // for every data
  let filteredData = data;
  // Search
  filteredData = filteredData.filter((entry) => {
    if (search.length < 3) return true;
    return entry.displayTitle.toLowerCase().includes(search.toLowerCase());
  });

  // Date
  filteredData = filteredData.filter((entry) => {
    if (checkDate(entry, filters)) return entry;
    return null;
  });

  // Price
  filteredData = filteredData.filter((entry) => {
    if (checkPrice(entry, filters)) return entry;
    return null;
  });

  // Status
  if (filters.booked || filters.bookable || filters.closed || filters.full) {
    filteredData = filteredData.filter((entry) => {
      const closed =
        new Date().getTime() < Date.parse(entry.bookingStartDatetime) ||
        Date.parse(entry.bookingEndDatetime) < new Date().getTime();
      const booked = eventIdList.includes(entry.eventId);
      const full = statsList?.find((d) => d.eventId === entry.eventId)?.isFull;

      let shouldReturn = false;

      if (booked && filters.booked) shouldReturn = true;
      else if (!closed && !full && filters.bookable) shouldReturn = true;
      else if (filters.full && full && !(filters.closed && closed) && !booked) shouldReturn = true;
      else if (filters.closed && closed && !(filters.full || full) && !booked) shouldReturn = true;
      else if (((filters.closed && closed) || (filters.full && full)) && !booked) shouldReturn = true;
      return shouldReturn;
    });
  }

  // Category

  // 1. union all event id's matching the categories
  const eventIdCategories = new Set<number>([]);
  eventGroups.forEach((eventGroup) => {
    if (filters.categories.includes(eventGroup.id.toString()))
      eventGroup.eventIDs.forEach((value) => eventIdCategories.add(value));
  });

  // 2. filter with the id
  if (filters.categories.length > 0) {
    filteredData = filteredData.filter((entry) => eventIdCategories.has(entry.eventId));
  }

  return filteredData;
};

interface UserEventWithFirst {
  first: boolean;
  data: UserEvent;
}

export interface UserTicketWithFirst {
  first: boolean;
  data: UserTicket;
}

export const classifyEventList = (
  data: UserEvent[] | UserTicket[],
  tickets?: boolean
): UserEventWithFirst[] | UserTicketWithFirst[] => {
  if (data === undefined || data === null || data.length === 0) {
    return [];
  }
  const classified: any[] = [];
  let unique = format(utcToZonedTime(data[0].startDatetime, 'Europe/Stockholm'), 'LL do');
  data.forEach((ev: UserEvent | UserTicket) => {
    classified.push({
      first: unique !== format(utcToZonedTime(ev.startDatetime, 'Europe/Stockholm'), 'LL do'),
      data: ev,
    });
    unique = format(utcToZonedTime(ev.startDatetime, 'Europe/Stockholm'), 'LL do');
  });
  classified[0].first = true;

  if (tickets) return classified as UserTicketWithFirst[];

  return classified as UserEventWithFirst[];
};

export const filterEventListAdmin = (data: UserTicket[], filter: BookingSearchFilter, search: string): UserTicket[] => {
  // check data is existing or filter are
  if (!data || data.length === 0) {
    return [];
  }

  // for every data
  let filteredData = data;

  // Search
  filteredData = filteredData.filter((entry) => {
    if (search.length < 3) return true;
    return entry.displayTitle.toLowerCase().includes(search.toLowerCase());
  });

  // Date
  filteredData = filteredData.filter((entry) => {
    if (checkDate(entry, filter)) return entry;
    return null;
  });

  // Price
  filteredData = filteredData.filter((entry) => {
    if (checkPrice(entry, filter)) return entry;
    return null;
  });

  // Attendance
  if (filter.attended.length !== 0 && filter.attended.length !== 2) {
    filteredData = filteredData.filter((entry) => {
      let shouldReturn = false;

      if (filter.attended.includes('attended') && entry.isAttending) shouldReturn = true;
      else if (filter.attended.includes('notAttended') && !entry.isAttending) shouldReturn = true;

      return shouldReturn;
    });
  }

  return filteredData;
};

export default filterEventListAdmin;

import React from 'react';

import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { IconButton, Typography, styled } from '@mui/material';

const SubtitleTypography = styled(Typography)(({ theme }) => ({
  color: `${theme.palette.surface.text}${theme.palette.other.opacityMedium}`,
}));

const HideOrShowButton: React.FC<{ onClick: () => void; expanded: boolean; hidden: boolean }> = ({
  onClick,
  expanded,
  hidden,
}) => {
  if (hidden) return <></>;
  return (
    <IconButton color="secondary" style={{ padding: '4px' }} onClick={onClick} size="large">
      {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
    </IconButton>
  );
};

interface Props {
  expanded?: boolean;
  title?: string;
  subtitle?: string;
  onClick?: () => void;
  hidden?: boolean;
}

/**
 * Header for event card. Displays title and the 'more' or 'less' icon button
 */
const EventHeader: React.FC<Props> = ({
  expanded = false,
  title = '',
  subtitle = '',
  onClick = () => null,
  hidden = false,
}) => {
  return (
    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      <div style={{ width: '80%' }}>
        <Typography style={{ fontWeight: 400 }} variant="h6" noWrap>
          {title}
        </Typography>
        <SubtitleTypography variant="body2" noWrap>
          {subtitle}
        </SubtitleTypography>
      </div>
      <HideOrShowButton onClick={onClick} expanded={expanded} hidden={hidden} />
    </div>
  );
};

export default EventHeader;
